import React, { useEffect, useMemo, useState } from 'react';
import './Hard75Page.css';
import * as amplitude from '@amplitude/analytics-browser';
import { useLocation } from 'react-router-dom';
import { isAndroid, isIOS, isDesktop } from 'react-device-detect';
import SignInWithApple from '../../components/SignInWithApple';
import { firestore, remoteConfig, fetchAndActivate } from '../../firebase';
import { getNumber } from "firebase/remote-config";
import { doc, getDoc, updateDoc, arrayUnion, setDoc, Timestamp } from 'firebase/firestore';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';


import '../../assets/fonts/IntegralCF-HeavyOblique.otf';
import '../../assets/fonts/Poppins-Bold.ttf';
import '../../assets/fonts/Poppins-Regular.ttf';

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function generatePlaceholderName() {
    const names = [
        "Achiever", "Warrior", "Champion", "Trailblazer", "Conqueror", "Pioneer",
        "Striver", "Victor", "Explorer", "Hero", "Survivor", "Navigator",
        "Endurer", "Challenger", "Overcomer", "Perseverer", "Motivator", "Inspirer",
        "Seeker", "Dreamer"
    ];

    const randomName = names[Math.floor(Math.random() * names.length)];
    return randomName || "Placeholder Name";
}

function redirectToAppStore() {
    const androidLink = "https://play.google.com/store/apps/details?id=com.apps.behard";
    const iosLink = "https://apps.apple.com/us/app/75-days-challenge-tough-soft/id6450723685?ppid=bfb22b12-4222-49a3-a70d-05fb1c5c04c9";

    var redirectLink = isAndroid ? androidLink : iosLink

    window.location.href = redirectLink
}

function Hard75Page({ challengeName }) {
    const query = useQuery();
    const invitedBy = query.get('utm_term');
    const inviteID = query.get('utm_medium');
    const [isAccepted, setIsAccepted] = useState(false);
    const [user, setUser] = useState(null);
    const [signInOn, setSignInOn] = useState(0);

    useEffect(() => {
        const fetchRemoteConfig = async () => {
            try {
                await fetchAndActivate(remoteConfig);
                const value = getNumber(remoteConfig, 'web_sign_in_on');
                setSignInOn(value);

                const identify = new amplitude.Identify()
                    .set('web_sign_in_on', value)
                    
                amplitude.identify(identify);

            } catch (error) {
                console.error("behard: Error fetching remote config values:", error);
            }
        };

        fetchRemoteConfig();
    }, []);

    useEffect(() => {

        const fetchAndAcceptInvite = async () => {
            try {

                const inviteDocRef = doc(firestore, "friend_invites", inviteID);
                const inviteDocSnap = await getDoc(inviteDocRef);

                if (inviteDocSnap.exists()) {
                    const inviteData = inviteDocSnap.data();
                    const users = inviteData.accepted_users;

                    // Assuming `user` is the current user and it's set somewhere in your app
                    if (user && !users.includes(user.uid)) {
                        await updateDoc(inviteDocRef, {
                            accepted_users: arrayUnion(user.uid),
                            accepted_at: Timestamp.now() 
                        });
                    }

                    var userName = user.providerData.displayName

                    if (!userName || userName.trim() === "") {
                        userName = generatePlaceholderName();
                    }

                    const userDocRef = doc(firestore, 'profiles', user.uid);
                    await updateDoc(userDocRef, {
                        id: user.uid,
                        name: userName,
                        friends: arrayUnion(invitedBy)
                    });

                    const invitorDocRef = doc(firestore, "profiles", invitedBy);
                    const inviteeDocRef = doc(firestore, "profiles", user.uid);

                    await updateDoc(invitorDocRef, {
                        friends: arrayUnion(user.uid)
                    });

                    await updateDoc(inviteeDocRef, {
                        friends: arrayUnion(invitedBy)
                    });

                } else {
                    console.log("No such document!");
                }

                const event = {
                    event_type: "challenge_accepted",
                    event_properties: {
                        page: 'Hard75 Page',
                        challenge: challengeName,
                        action: 'Accept Challenge',
                        invitedBy: invitedBy,
                        inviteID: inviteID 
                    }
                };
                
                amplitude.track(event);

                redirectToAppStore();

            } catch (error) {
                console.error("Error fetching invite: ", error);

                if (isAccepted) { 
                    redirectToAppStore();
                }
            }
        };

        fetchAndAcceptInvite();


    }, [inviteID, user]);


    function acceptButtonClick() {
        const event = {
            event_type: "accept_button_clicked",
            event_properties: {
                page: 'Hard75 Page',
                challenge: challengeName,
                action: 'Accept Challenge',
                invitedBy: invitedBy,
                inviteID: inviteID
            }
        };

        amplitude.track(event);
        setIsAccepted(true);

        if (signInOn == 0) {

            redirectToAppStore();
        }
    }

    return (
        <div className="hard75-container">
            <h1 className="hard75-title">YOU HAVE BEEN CHALLENGED</h1>
            <div className="hard75-content-container">
                <p className="middle-container-title">
                    {isAccepted && (signInOn == 1) ? "Sign in to accept" : `Join ${challengeName} Challenge`}
                </p>
                <p className="content-text">
                    Team up for challenges, support and track progress together.
                </p>
                <div className="image-container"></div>
                {isAccepted && (signInOn == 1) ? (
                    <SignInWithApple user={user} setUser={setUser} />
                ) : (
                    <button className="hard75-button" onClick={acceptButtonClick}>
                        Accept Challenge
                    </button>
                )}
            </div>
        </div>
    );
}
export default Hard75Page;
